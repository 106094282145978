import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Div } from '../../../../framework';
import { useTalentService } from '../../../../service/talent.service';
import { useFileManageService } from '../../../../service/filemanage.service';
import { RootState } from '../../../../store/store';
import MediaGalleryManager from '../../../../component/MediaGalleryManager/MediaGalleryManager';
import {
  GalleryItem,
  MediaFile,
  MediaManagerItem,
} from '../../../../helper/type';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';
import * as analyticService from '../../../../service/analytic.service';
import { IdentityTraits } from '../../../../service/analytic.service';

import './Media.scss';

const { Title, Paragraph } = Typography;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const MediaPage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const fileManageService = useFileManageService();
  const edit = new URLSearchParams(search).get('edit');
  const [isProcessing, setIsProcessing] = useState(false);
  const [mediaItems, setMediaItems] = useState<MediaManagerItem[]>([]);
  const [videos, setVideos] = useState<GalleryItem[]>([]);
  const [hasError, setHasError] = useState(false);

  const isImpersonator =
    impersonator.impersonateId && impersonator.impersonateKey;

  useEffect(() => {
    setStep(3);
  }, []);

  useEffect(() => {
    if (talent.gallery) {
      setMediaItems(convertToMediaItems(talent.gallery));
      setVideos(talent.gallery.filter((item) => item.type === 'video'));
    }
  }, [talent]);

  const convertToMediaItems = (items: GalleryItem[]): MediaManagerItem[] => {
    return items
      .filter((item) => item.type === 'photo')
      .map((item) => ({
        url: item,
        isNew: false,
      }));
  };

  const convertToGalleryItems = (items: MediaManagerItem[]): GalleryItem[] => {
    return items.map((item) => ({
      ...item.url,
    }));
  };

  const uploadPhotos = async (): Promise<GalleryItem[]> => {
    const uploadedItems: GalleryItem[] = [];
    const talentId = impersonator.impersonateId || authUser.username;

    for (let i = 0; i < mediaItems.length; i++) {
      const fileName = nanoid(10);
      if (mediaItems[i].file && mediaItems[i].isNew) {
        const [uploadedOri, uploadedThumb] = await Promise.allSettled([
          fileManageService.uploadFile({
            scope: 'talent',
            directory: talentId,
            file: (mediaItems[i].file as MediaFile).original,
            fileName,
          }),
          fileManageService.uploadFile({
            scope: 'talent',
            directory: talentId,
            file: (mediaItems[i].file as MediaFile).thumbnail,
            fileName: `${fileName}.thumbnail`,
          }),
        ]);

        if (
          uploadedOri.status === 'fulfilled' &&
          uploadedThumb.status === 'fulfilled'
        ) {
          uploadedItems.push({
            original: uploadedOri.value.url,
            thumbnail: uploadedThumb.value.url,
            type: 'photo',
          });
        } else {
          const errorMessage: string | undefined =
            (uploadedOri as PromiseRejectedResult).reason?.message ||
            (uploadedThumb as PromiseRejectedResult).reason?.message;

          if (errorMessage?.includes('Network Error')) {
            message.error(
              'We detected a network error, kindly remove all the photos and upload them again. thank you. 😊'
            );
          } else {
            message.error(
              "Oops, it looks like some photos didn't upload correctly, kindly refresh the page and try again. 😊"
            );
          }
        }
      }
    }

    return uploadedItems;
  };

  const updateTalentGallery = async (newPhotos: GalleryItem[]) => {
    const gallery = [
      ...videos,
      ...newPhotos,
      ...convertToGalleryItems(mediaItems.filter((item) => !item.isNew)),
    ];

    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile({ gallery }, talentId);
    dispatch(setPartialTalentProfile({ gallery }));
  };

  const handleSubmit = async () => {
    if (mediaItems.length === 0) {
      setHasError(true);
    } else {
      const newPhotos = await uploadPhotos();
      await updateTalentGallery(newPhotos);

      const location = {
        pathname: '/talent/profile/picture',
        search: generateSearchParamString({ edit }),
        state,
      };

      if (!isImpersonator) {
        await analyticService.identify(authUser.username, {
          [IdentityTraits.TYPE]: 'talent',
        });
      }
      await analyticService.trackEvent('upload images');

      history.push(location);
    }
  };

  return (
    <Div className='page-talent-form-media'>
      <Title level={3}>Photos</Title>
      <Paragraph>
        Please upload comcard and a few lifestyle photos. To give you a better
        idea, here are some{' '}
        <a
          href='https://drive.google.com/drive/folders/1ldNTeVK8_at7zcOGmsR8K7_d-LkGYiNc'
          target='_blank'
          rel='noreferrer'
        >
          examples
        </a>
        .
      </Paragraph>
      <MediaGalleryManager
        value={mediaItems}
        onChange={setMediaItems}
        onStatusChange={setIsProcessing}
      />
      <Paragraph
        type='secondary'
        className={`help-message ${hasError ? '--error' : ''}`.trim()}
      >
        To complete your profile creation process, please upload at least 1
        photo
      </Paragraph>
      <Div mv='l'>
        <Div mb='m'>
          <Button
            onClick={handleSubmit}
            disabled={isProcessing}
            type='primary'
            htmlType='submit'
            block
          >
            {mediaItems.filter((item) => item.isNew).length
              ? 'Upload Photos & Next'
              : 'Next'}
          </Button>
        </Div>
        <Button
          block
          onClick={() => {
            const location = {
              pathname: '/talent/profile/skill',
              search: generateSearchParamString({ edit }),
              state,
            };

            history.push(location);
          }}
        >
          Back
        </Button>
      </Div>
    </Div>
  );
};

export default MediaPage;
