import { Typography } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Div } from '../../../../framework';
import { format as formatDateString, parseISO } from 'date-fns';
import {
  CheckOutlined,
  CloseOutlined,
  TranslationOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  CheckSquareOutlined,
  FieldTimeOutlined,
  VideoCameraOutlined,
  GlobalOutlined,
  CopyrightOutlined,
} from '@ant-design/icons';
import { Activity, Project, Role } from '../../../../helper/type';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useJobService } from '../../../../service/job.service';
import { useActivityService } from '../../../../service/activity.service';
import { useRatingService } from '../../../../service/rating.service';
import { setActivityFlyoutOpen } from '../../../../store/app.slice';
import { activityActionType } from '../../../../helper/constants';
import { featureToggle } from '../../../../../config/app.config';

import './Confirmation.scss';
import {
  calcRolePayment,
  getRolePaymentRate,
} from '../../../../helper/job/calcRolePayment';

const { Text } = Typography;

type Props = {
  activity: Activity;
};

const Confirmation: FunctionComponent<Props> = ({ activity }) => {
  const { activityId } = activity;
  const [role, setRole] = useState<Role>();
  const [project, setProject] = useState<Project>();
  const history = useHistory();

  const jobService = useJobService();
  const activityService = useActivityService();
  const ratingService = useRatingService();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivityFlyoutOpen({ isOpen: false }));

    (async () => {
      const data = await jobService.getRole(activity.roleId);
      setRole(data);
      setProject(data.project);
    })();
  }, [activityId]);

  const replyPayload = () => ({
    activityId: activity.activityId,
    hirerId: activity.hirerId,
    roleId: activity.roleId,
    talentId: activity.talentId,
    applicationId: activity.applicationId,
    scheduledDatetime: activity.scheduledDatetime,
  });

  const handleConfirm = async () => {
    if (activity && role && project) {
      const { hirerId, talentId, roleId } = activity;
      await activityService.talentReplyConfirmation({
        ...replyPayload(),
        actionType: activityActionType.talentAcceptConfirmation,
        replyStatus: 'SCHEDULED',
        talentNeeded: role.countNeeded,
      });

      if (featureToggle.rating) {
        await ratingService.createPendingRating({
          hirerId,
          talentId,
          roleId,
          projectId: project.id,
          shootingDate: activity.scheduledDatetime as string,
        });
      }

      history.push('/talent/jobs');
    }
  };

  const handleReject = async () => {
    if (activity && role && project) {
      await activityService.talentReplyConfirmation({
        ...replyPayload(),
        actionType: activityActionType.talentRejectConfirmation,
        replyStatus: null,
      });
      history.push('/talent/jobs');
    }
  };

  return (
    <Div className='confirmation-card'>
      {role && project ? (
        <Div>
          <Text className='project-title'>{project.title}</Text>

          <Div p='xs' pl='l' pr='l' className='role-details'>
            <Div pb='xs' className='role-description'>
              Job Description: {project.description}
            </Div>

            <Div className='project-info bold' pb='xs'>
              <EnvironmentOutlined className='icon' />
              {project.shootLocation || 'TBC'}
            </Div>
            <Div className='project-info bold' pb='xs'>
              <CalendarOutlined className='icon' />
              {!project.shootDate ? 'TBC' : null}
              {project.shootDate?.from
                ? formatDateString(
                    parseISO(project.shootDate.from),
                    'dd MMM yyy'
                  )
                : ''}
              {project.shootDate?.to.length >= 0 &&
              project.shootDate?.to !== project.shootDate?.from
                ? ` - ${formatDateString(
                    parseISO(project.shootDate.to),
                    'dd MMM yyy'
                  )}`
                : ''}
            </Div>
            <Div pb='xs' className='role-description'>
              Role Description: {role.description}
            </Div>

            {project.type && (
              <Div className='project-info' pb='xs'>
                <AppstoreOutlined className='icon' />
                {project.type}
              </Div>
            )}
            {project.contractual !== undefined && (
              <Div className='project-info' pb='xs'>
                <CheckSquareOutlined className='icon' />
                {!project.contractual ? 'Non-Contractual' : 'Contractual'}
              </Div>
            )}
            {project.paymentTerm && (
              <Div className='project-info' pb='xs'>
                <FieldTimeOutlined className='icon' />
                {project.paymentTerm?.value} {project.paymentTerm?.period}{' '}
                payment term
              </Div>
            )}
            {project.media && project.media.length > 0 && (
              <Div className='project-info' pb='xs'>
                <VideoCameraOutlined className='icon' />
                {project.media.join(', ')}
              </Div>
            )}

            {project.mediaUsageCountries &&
              project.mediaUsageCountries.length > 0 && (
                <Div className='project-info' pb='xs'>
                  <GlobalOutlined className='icon' />
                  {project.mediaUsageCountries.join(', ')}
                </Div>
              )}
            {project.usageDuration &&
              (project.usageDuration.perpetual !== undefined ||
                project.usageDuration.duration !== undefined) && (
                <Div className='project-info' pb='xs'>
                  <CopyrightOutlined className='icon' />
                  {project.usageDuration?.perpetual
                    ? 'Perpetual'
                    : project.usageDuration?.duration ?? ''}
                </Div>
              )}

            <Div pb='xs' className='role-requirements'>
              <span className='capitalize bold'>{role.type}</span>:{' '}
              <span className='capitalize'>{role.gender}</span>,{' '}
              <span className='capitalize'>
                {role.raceEthnicity ? role.raceEthnicity.join(', ') : role.race}
              </span>
              , {role.ageFrom}-{role.ageTo} years old
            </Div>

            {role.languages?.length > 0 && (
              <Div pb='xs' className='capitalize'>
                <TranslationOutlined className='icon' />
                {role.languages?.join(', ')}
              </Div>
            )}
            <Div pb='xs'>
              <DollarOutlined className='icon' />
              <span className='bold'>
                RM {calcRolePayment(role.payment)}
              </span>,{' '}
              <span className='role-description'>
                nett after {getRolePaymentRate(role.payment || 0) * 100}%
                platform fee
              </span>
            </Div>
          </Div>

          <Div className='role-action' flex>
            <button onClick={handleReject}>
              <CloseOutlined />
              <Text> Reject</Text>
            </button>
            <button onClick={handleConfirm}>
              <CheckOutlined />
              <Text>Accept Job</Text>
            </button>
          </Div>
        </Div>
      ) : null}
    </Div>
  );
};

export default Confirmation;
